import React from 'react';
import image1 from '../images/support-initiative.jpg';
import image2 from '../images/new-program-launch.jpg';
import image3 from '../images/innovative-care-tech.jpg';

const newsUpdates = [
  {
    image: image1,
    date: "23 Jul",
    author: "",
    comments: 2,
    title: "Launching Our New Support Initiative",
    excerpt: "We are excited to introduce a new support initiative designed to enhance the quality of life for young adults with learning difficulties. Discover the details of this program and its impact on our community."
  },
  {
    image: image2,
    date: "15 Aug",
    author: "",
    comments: 3,
    title: "Introducing Our New Life Skills Training Program",
    excerpt: "Our new life skills training program is now available, focusing on essential skills for young adults. Learn more about how this program will support personal development and independence."
  },
  {
    image: image3,
    date: "10 Sep",
    author: "",
    comments: 5,
    title: "Innovative Technologies in Adult Support Services",
    excerpt: "We have integrated innovative technologies into our support services to better assist young adults. Explore the latest advancements and their benefits for enhancing our care approach."
  }
];

const NewsUpdates = () => (
  <div className="site-section cssanimation fadeInBottom">
    <div className="container">
      <div className="row mb-5 justify-content-center">
        <div className="col-7 text-center">
          <div className="heading">
            <h2 className="text-black">News &amp; Updates</h2>
          </div>
          <p>Stay informed about the latest developments and programs at Emerald Care Group. From new initiatives to technological advancements, find out how we’re continually improving our services for young adults.</p>
        </div>
      </div>
      <div className="row">
        {newsUpdates.map((item, index) => (
          <div className="col-md-4" key={index}>
            <div className="blog-entry">
              <a href="/" className="d-block">
                <img src={item.image} alt={item.title} className="img-fluid" />
              </a>
              <br />
              {/* <div className="post-meta d-flex justify-content-center">
                <span>
                  <span className="icon-calendar"></span>
                  <span>{item.date}</span>
                </span>
                <span>
                  <span className="icon-user"></span>
                  <span>{item.author}</span>
                </span>
                <span>
                  <span className="icon-comment"></span>
                  <span>{item.comments} </span>
                </span>
              </div> */}
              <h2><a href="/">{item.title}</a></h2>
              <p>{item.excerpt}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default NewsUpdates;
